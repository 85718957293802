// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1279px) {

    .detail-omnibus {
        align-self: flex-start;
        margin-top: 0;
        font-size: 11px;
        text-align: left;
    }

    main.aside {
        grid-template-columns: minmax(0, 1fr);
        grid-gap: var(--rowGap) 0;
    }

    * {
        -webkit-tap-highlight-color: rgba(189, 195, 199, .75);
        -webkit-text-size-adjust: none;
    }

    input[type=text],
    input[type=password],
    input[type=email],
    textarea {
        font-size: 16px;
        padding: 0 14px;
    }

    select, .field-wrapper:not(.checking-reg) select {
        padding: 14px 34px 0 14px;
    }

    textarea {
        padding: 10px 14px;
    }

    #facebook-box,
    .detail-loupe {
        display: none;
    }

    #content {
        width: 100%;
        clear: both;
    }

    .sidebar {
        width: auto;
        display: contents;

        .sidebar-tabs {
            display: none;
        }
    }

    // SEARCH ENGINE
    #search-engine {
        display: none;
    }

    .newsletter-bg {
        background-image: none !important;
    }

    .newsletter-input {
        width: 100%;
    }

    .footer-content {
        flex-direction: column;
        padding: 20px 0;
    }

    .footer-item {
        width: 100% !important;
    }

    .footer-bottom-bar {
        flex-direction: column;
        gap: 16px;
    }

    body:has(.gs-filtr-container) .filter-trigger {
        display: flex;
    }

    // LISTING NAVIGATION
    .navigation-wrapper {
        gap: 10px;
    }

    .inner-wrapper [data-dropdown-type="pageSize"] {
        margin-left: auto;
    }

    .dropdown-picker[data-dropdown-type="sort"] {
        display: none;
    }

    .listing-button {
        height: 40px;
        font-size: 13px;
        min-width: 200px;

        &.filter-trigger {
            min-width: 0;
            gap: 5px;
            align-items: center;
            font-weight: bold;
            color: @header-color;
            border-color: @header-color;
            justify-content: center;

            &:before {
                content: 'i sortowanie';
                position: relative;
                top: initial;
                left: initial;
                padding: 0;
                color: inherit;
                font-size: inherit;
                font-weight: inherit;
                order: 1;
            }

            &:after {
                top: calc(50% - 1px);
                right: 16px;
            }
        }
    }

    // FILTER
    #filter {
        display: none;
    }

    // OTHER
    hr {
        margin: 20px 0;
    }

    .overlay-visible {
        overflow: hidden;
        height: 100dvh;
        width: 100dvw;
        position: fixed;
        top: 0;
    }

    main:not(.aside) #tree {
        display: block;
    }

    .tree-canvas {
        position: fixed;
        background: rgb(0, 0, 0) none repeat scroll 0% 0%;
        will-change: opacity;
        transition: opacity 0.3s ease 0s;
        inset: 0px;
        width: 100dvw;
        height: 100dvh;
        z-index: 1001;
        pointer-events: none;
        opacity: 0;
    }

    #tree {
        position: fixed;
        top: 0;
        left: 0;
        transform: translate3d(-100%, 0, 0);
        overflow: hidden;
    }

    .user-custom-content p {
        margin-bottom: 10px;
    }

    .cart-header {
        padding-block: 20px;
    }

    // DESC NAVIGATION
    .desc-navigation-wrapper {
        margin-bottom: 10px;

        &.sticking {
            width: 100dvw;
            transform: translateX(-10px);
        }

        &:before,
        &:after {
            width: 16px;
            height: calc(100% - 4px);
        }
    }

    .desc-navigation button {
        padding: 6px 16px;
        font-size: 13px;

        &:after {
            height: 14px;
        }
    }

    // DETAIL ORIENTATION CHANGE
    .detail-main-wrapper {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: relative;
    }

    #detail-images {
        align-items: center;
        flex-direction: column;
        gap: 20px;
        height: auto;
        left: auto;
        margin: 0 auto;
        position: relative;
        top: auto;
        width: 100%;
    }

    .product-images-mini {
        order: initial;
        height: auto;
        width: 100%;

        .swiper-wrapper {
            transform: none !important;
            width: auto;
            justify-content: center;
        }

        .size-custom {
            width: 10px;
            height: 10px;
            background-color: @border-color;
            border-radius: 50%;
            margin: 0 5px;
            padding: 0 !important;
        }

        img {
            display: none !important;
        }
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////

    //// 1279 BASE
    .headline {
        font-size: 20px;
        margin-bottom: 10px;
    }

    //// 1279 HEADER
    .top-bar {
        padding: 3px 0 4px;
        font-size: inherit;
    }

    .shop-header {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        background-color: white;
        .shadow();
        transition: transform var(--animSpeed) ease-in-out;

        &.stuck {
            transform: translateY(var(--headerScrollTransform));
        }
    }

    .shop-header-container {
        height: 53px;
    }

    #tree-top,
    .mainmenu {
        display: none;
    }

    // 1279 HEADER TOOLS WRAPPER
    .header-tools-wrapper > * {
        padding-top: 25px;

        span,
        b {
            display: none;
        }

        &:before {
            transform: translateX(-50%);
            font-size: 22px;
            top: 0;
            left: 50%;
        }

        em {
            display: block;
            color: @header-color;
            font-style: normal;
            text-transform: uppercase;
            font-size: 9px;
            font-weight: 600;
            line-height: 14px;
        }
    }

    #cart-box {

        i {
            right: 4px;
        }
    }

    #wishlist-box {

        &:before {
            font-size: 20px;
            top: 1px;
        }

        &:after {
            right: 6px;
            content: '';
        }

        &[data-wishlist-count="0"]:after {
            display: none;
        }
    }

    #wishlist-box:after,
    #cart-box i {
        position: absolute;
        top: 3px;
        border-radius: 50%;
        font-size: 0;
        background-color: @main-color;
        width: 8px;
        height: 8px;
    }

    .hamburger-icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 25px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: center;
        align-items: center;

        span {
            display: block;
            width: 95%;
            height: 2px;
            border-radius: 1px;
            background-color: @header-color;

            &:nth-child(1) {
            }

            &:nth-child(2) {
            }

            &:nth-child(3) {
            }
        }
    }

    // iOS 12 fixes
    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        .header-tools-wrapper {
            
            > * {
                margin-left: 10px;
            }
        }

        .hamburger-icon {
            .grid();
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, 6px);
            gap: 0;
            justify-items: center;
            align-items: center;
        }

        .tree-canvas {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    /////////////////////////////

    .news-banner a.main-page-product-all {
        top: 0;
    }

    .main-banner-wrapper {
        margin-top: 10px;
    }

    .slider-with-products .swiper-button-prev,
    .slider-with-products .swiper-button-next {
        margin-top: -2px;
    }

    .producers-banner {
        width: 100%;
    }

    #detail-tabs nav span {
        border-width: 1px;
        font-size: 15px;
    }

    .logo {
        height: 32px;

        span:before {
            margin: 0 20px;
        }
    }

    .newsletter-header b {
        font-size: 22px;
    }

    a.main-page-product-all {
        margin-top: 3px;
    }

    // MAIN BAR
    .mainmenu a {
        height: 34px;
        font-size: inherit;
    }

    // ICONS BANNER
    .icon-banner-wrapper {
        gap: 10px;
    }

    // MAIN PAGE SLIDERS
    .slider-with-products .headline {
        margin-bottom: 10px;
    }

    // PRODUCT
    .product-badges {
        font-size: 12px;
    }

    .slider-with-products.slider2 {
        padding: 20px;
        align-content: start;
    }

    .product-single-promoted > div {
        gap: 20px;
    }

    .return-to-top {
        display: none;
    }
    
    .goshop-projects {
        justify-content: start;
        text-align: initial;
        align-items: start;
        
        em {
            font-size: 20px;
        }
    }

    // NEWS BANNER
    .news-banner {
        padding-top: 0;

        &:before {
            display: none;
        }

        .headline {
            margin-bottom: 10px;
        }
    }

    .news-article {
        width: 100%;
        margin-right: 0;
        padding-left: 310px;

        figure {
            width: 286px;
        }
    }

    a.footer-contact-link {
        margin-bottom: 20px;
    }

    // NEWSLETTER
    #newsletter {
        height: auto;
        padding: 20px 10px;
    }

    .footer-item.shortcut div {
        padding-top: 0;
    }

    // BREADCRUMB
    #breadcrumb {
        padding-block: 10px;

        li:after {
            margin: 0 5px;
        }
    }

    // CATEGORY DESCRIPTION
    .category-image {
        margin-right: 10px;
    }

    // DETAIL
    .detail-headline {
        padding: 0;
        margin-bottom: 0;
        text-align: center;
    }

    .product-images {
        margin: 0 auto;
        order: -2;
    }

    .detail-swiper-button {
        height: 48px;
    }

    #detail-left {
        padding-left: 0;
        min-height: 0;
        margin-bottom: 0;
    }

    #detail-right {
        position: relative;
        box-shadow: none;
        top: auto;
        padding: 10px;
    }

    .detail-options dl:last-child {
        margin-bottom: 16px;
    }
   
    .detail-accordion-row {
        padding: 12px 0;
    }

    .detail-pricing b {
        font-size: 20px;
    }

    .detail-pricing del {
        font-size: 15px;
        top: -2px;
    }

    .detail-badges span {
        margin-bottom: 8px;
    }

    .detail-badges,
    .detail-pricing {
        justify-content: flex-start;
    }

    span[data-role="sku"]:last-of-type, span[data-role="ean"]:last-of-type {
        margin-bottom: 10px;
    }

    .detail-producer-info {
        padding: 10px 0;
        margin-bottom: 10px;
    }

    .detail-short-description {
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    .detail-buttons {
        gap: 10px;

        button {
            gap: 8px;
        }
    }

    .social-share {
        margin-top: 10px;
    }

    #main-info {
        margin-top: 0;
    }
}