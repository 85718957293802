// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 600px) {
    .detail-share-links-wrapper span {
        width: 100%;
    }

    .stars-rating.average-rating {
        top: -2px;
    }

    .gs-tabs {

        nav {
            flex-direction: column;

            button {
                width: 100%;
            }
        }
    }

    .user-custom-content {

        iframe {
            max-width: 100%;
        }
    }

    .privacy-box {
        padding: 10px;
        gap: 10px;
    }

    .privacy-box__header {
        gap: 10px;

        img {
            height: 20px;
        }

        strong {
            font-size: 14px;
        }
    }

    .privacy-box__actions {
        flex-wrap: wrap;

        button {
            min-height: 35px;
        }
    }

    .privacy-box__actions button.privacy-accept-all {
        width: 100%;
        order: -1;
        flex: 0 0 auto;
        min-height: 45px;
    }

    .pagination {
        gap: 4px;

        a {
            width: 30px;
            height: 30px;
        }
    }

    #breadcrumb {
        display: flex;
        margin-left: -10px;
        width: ~"calc(100% + 20px)";
        justify-content: flex-start;
        position: relative;

        .breadcrumb-end {
            display: block;
            position: absolute;
            top: 0;
            top: 0;
            height: calc(100% - 16px);
            width: 20px;
            pointer-events: none;

            &:nth-of-type(1) {
                left: -7px;
                background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgb(255, 255, 255), rgb(255, 255, 255));
            }

            &:nth-of-type(2) {
                right: -7px;
                background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255), rgb(255, 255, 255));
            }
        }

        ul {
            flex-wrap: nowrap;
            overflow-y: auto;
            justify-content: flex-start;
            flex-direction: row-reverse;
            padding-bottom: 5px;
            .scrollbar();

            @supports(selector(::-webkit-scrollbar)) { // chrome only scrollbar
                &::-webkit-scrollbar-track {
                    margin-inline: 5px;
                }
            }
        }

        li {
            white-space: nowrap;

            &:last-of-type {
                padding-right: 10px;
            }

            &:first-of-type {
                padding-left: 10px;
            }

            &:nth-of-type(1) {
                order: 10;
            }

            &:nth-of-type(2) {
                order: 9;
            }

            &:nth-of-type(3) {
                order: 8;
            }

            &:nth-of-type(4) {
                order: 7;
            }

            &:nth-of-type(5) {
                order: 6;
            }
        }
    }

    #gsGalleryContainer > a {
        width: 100%;
    }

    .availability-modal-wrapper .wrapper {
        justify-content: center;
        gap: 10px;

        > p {
            text-align: center;
        }

        > .availability-product {
            justify-content: center;
        }
    }

    .availability-modal-wrapper .availability-product {
        gap: 10px;
        padding: 10px 0;
    }

    .availability-modal-wrapper .input-wrapper {
        flex-wrap: wrap;

        > * {
            width: 100%;
        }
    }

    .product-images {
        width: 100%;
    }

    .product-images .size-full {
        width: 100%;
        height: auto;
        aspect-ratio: 1;
    }

    .detail-amount {
        width: 100%;
        margin: 0 0 10px;
    }

    .pills-group:before,
    .remove-all-filters {
        font-size: 11px;
    }

    .single-pill {
        font-size: 11px;
        padding: 2px 5px;
    }

    #detail-images {
        --swiper-navigation-sides-offset: 0;
    }

    .detail-amount button:before {
        font-size: 22px !important;
    }

    @media (max-width: 350px) {
        .logo {
            flex-shrink: 1;
            max-width: 40%;
            aspect-ratio: initial;
        }
    }

    // LISTING NAVIGATION
    .dropdown-picker[data-dropdown-type="pageSize"] {
        flex-grow: 1;

        .dropdown-button {
            width: 100%;
        }
    }

    @media (max-width: 380px) {
        .navigation-wrapper {
            flex-direction: column;
        }

        .dropdown-picker {
            width: 100%;
            z-index: 11;
        }

        .listing-button {
            min-width: 0 !important;
            width: 100% !important;
        }

        .filter-trigger {
            order: 1;
        }
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////

    .comment .comment-response {
        margin-left: 15px;
        padding: 10px;
    }

    .header-tools-wrapper {
        gap: 12px;
    }

    .navigation-wrapper {
        padding-top: 0;
        border-top: 0;
    }

    a.footer-contact-link {
        display: flex;
        width: fit-content;
        margin-left: 0;
    }

    .listing-wrapper {
        gap: 10px;
    }

    .listing-wrapper > hr {
        display: none;
    }

    .header-social-links {
        display: none;
    }

    .header-contact {
        margin-right: 0 !important;
    }

    .category-image {
        display: none;
    }

    .mainmenu {
        padding: 0 10px;

        li:nth-child(1) {
            display: none;
        }
    }

    .logo {

        span {
            display: none;
        }
    }

    @media (max-width: 350px) {
        .icon-banner-wrapper {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    .main-banner {
        height: 270px;

        .text-wrapper {
            padding: 10px;
            max-width: ~"calc(100% - 20px)";
            left: 10px;
            bottom: 23px;

            b {
                font-size: 16px;
            }
            
            span {
                font-size: 13px;
            }
        }
    }

    .swiper-pagination.banner-pagination {
        bottom: -7px;
        left: 10px;
    }

    .main-greeting {
        padding: 10px;
    }

    .slider-with-products .headline {
        margin-bottom: 10px;
    }

    .slider-with-products {

        .swiper-button-prev,
        .swiper-button-next {
            width: 25px;
        }
    }

    .slider-with-products .swiper-button-prev {
        margin-left: 10px;
    }

    a.main-page-product-all {
        margin: 3px 0 0 0;
    }

    .news-banner-wrapper {
        gap: 10px;
    }

    .news-article {
        width: ~"calc((100% - 10px) / 2)";
        padding: 0;

        figure {
            position: static;
            width: 100%;
            height: auto;
            margin-bottom: 10px;
        }

        b {
            font-size: 13px;
        }

        p {
            margin-top: 8px;
        }
    }

    .news-banner a.main-page-product-all {
        top: 3px;
    }

    #detail-traits {
        display: block;

        tbody, tr, td {
            display: block;
            width: 100%;
        }

        tr {
            padding: 8px 0;
        }

        td {
            padding: 0 0;

            &:nth-child(1) {
                text-align: left;
                border-bottom: 0;
            }

            &:nth-child(2) {
                padding-bottom: 8px;
                padding-top: 3px;
            }
        }
    }

    .user-custom-content #askbox, .add-comment-form {
        padding-right: 0;

        &:after {
            display: none;
        }
    }

    .producers-banner {
        padding: 0 30px;
    }

    // MAIN PAGE PROMO ROW
    .main-page-promo-row {
        flex-direction: column;
        gap: 20px;
    }

    .slider-with-products.slider2, .goshop-projects {
        width: 100%;
    }

    .slider2 {

        .swiper-slide:not(.swiper-slide-active) {
            opacity: 0;
        }

        .product {
            width: 100% !important;
            margin: 0 !important;
        }
    }

    .product-single-promoted {

        figure {
            width: 40%;
            height: 100%;
        }
    }

    .product-single-promoted-info {
        gap: 0;
    }

    .category-header-wrapper {
        display: contents;
    }

    .category-description {
        order: 2;
    }
}