// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1100px) {
    .comments-list {
        gap: 20px;
    }

    .comment .author:before {
        display: none;
    }

    .comment .added-date {
        margin-left: 0;
        width: 100%;
    }

    .comment .purchase-confirmed {
        width: 100%;
    }

    #detail-traits td {
        padding: 8px 15px;
    }

    @media (max-width: 675px) {
        .icon-banner-wrapper {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////
    .header-shipment {
        display: none;
    }

    .main-banner-wrapper {
        flex-direction: column;
        gap: 10px;
    }

    .main-banner {
        width: 950px;
        max-width: 100%;
        margin: 0 auto;

        .text-wrapper {

            b {
                font-size: 20px;
            }

            span {
                font-size: 14px;
            }
        }
    }

    .main-page-promo-row {
        gap: 10px;

        > * {
            width: 50%;
            flex: 1;
        }
    }

    .slider-with-products.slider2 {
        padding: 10px;
        gap: 5px;

        a.main-page-product-all {
            margin: 0;
            width: 100%;
            display: flex;
            align-items: center;
        }
    }

    .product-single-promoted {

        > div {
            gap: 10px;
        }

        figure {
            width: 40%;
            height: 100%;
        }

        .product-single-promoted-info {
            gap: 5px;
        }

        .product-name {
            font-size: inherit;
        }
    }

    .goshop-projects {
        padding: 10px;

        em {
            font-size: 18px;
            margin-bottom: 15px;
        }

        img {
            align-self: center;
        }

    }


    .main-greeting {
        width: 100%;
        height: auto;
        background-color: white;
        border: 4px solid @sec-color;
        padding: 12px;
    }

    .main-greeting-headline {
        color: @header-color;

        em {
            font-size: 22px;
        }
    }

    .main-greeting-text {
        color: @font-color;
    }

    a.main-greeting-link {
        background-color: @sec-color;
        color: white;
    }

    a.main-greeting-link {
        margin-top: 10px;
    }

    .product {
        padding: 10px;
    }

    .product-badges {
        top: 10px;
        left: 10px;
        width: ~"calc(100% - 20px)";
    }

    .product-price b {
        font-size: 14px;
    }

    .product-price del {
        font-size: 13px;
    }

    .footer-item.submenu ul {
        columns: 1;
    }
}