// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1460px) {

    main {
        padding-bottom: var(--rowGap) !important;
    }

    .inner {
        width: 100%;
        padding: 0 10px;
    }

    .main-banner img {
        position: absolute;
        top: 0;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
        max-width: none;
    }

    .main-banner .swiper-slide-active {
        z-index: 1;
    }

    .footer-bottom-bar {
        padding: 30px 0 60px;
    }
    
    body.privacy-box-visible {
        overflow: hidden;
        
        #container {
            filter: blur(5px);
            pointer-events: none;
        }
    }

    // STICKY PAGE BUTTONS
    .return-to-top,
    .privacy-box-trigger {
        bottom: 10px;
        width: 35px;
        height: 35px;

        &.right-side-cookies {
            right: 10px;
        }
    }

    .return-to-top {

        &:after {
            font-size: 12px;
        }

        &:before {
            inset: -2px;
        }
    }

    body:has(.left-side-cookies) {

        .privacy-box-trigger {
            left: 10px;

            &:before {
                left: 15px;
                padding: 0 25px 0 34px;
            }
        }

        .return-to-top {
            right: 10px;
        }
    }

    body:has(.right-side-cookies) {

        .privacy-box-trigger {
            right: 10px;


            &:before {
                right: 15px;
                padding: 0 34px 0 25px;
            }
        }

        .return-to-top {
            left: 10px;
        }
    }

    .privacy-box {
        bottom: auto;
        right: auto;
        left: 15px !important;
        right: 15px !important;
        top: 50%;
        transform: translateY(-50%);
        max-width: 100vw;
        max-height: ~"calc(100% - 40px)";
    }
}

@media (max-width: 1460px) and (min-width: 1280px) {

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////

    main.aside {
        grid-template-columns: 260px minmax(1px, 1fr);
        gap: var(--rowGap) 30px;
    }

    .detail-main-wrapper {
        grid-template-columns: 555px minmax(0, 1fr) minmax(0, 1fr);
        --fullWidth: 440px;
        --fullHeight: 440px;
        --customWidth: 95px;
        --customHeight: 95px;
    }

    /////////////////////////

    .product-hover > strong {
        max-height: 44px;
        overflow: hidden;
    }

    #detail-right {
        padding: 20px;
    }
}