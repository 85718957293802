@media (max-width: 1460px) {
  main {
    padding-bottom: var(--rowGap) !important;
  }
  .inner {
    width: 100%;
    padding: 0 10px;
  }
  .main-banner img {
    position: absolute;
    top: 0;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    max-width: none;
  }
  .main-banner .swiper-slide-active {
    z-index: 1;
  }
  .footer-bottom-bar {
    padding: 30px 0 60px;
  }
  body.privacy-box-visible {
    overflow: hidden;
  }
  body.privacy-box-visible #container {
    filter: blur(5px);
    pointer-events: none;
  }
  .return-to-top,
  .privacy-box-trigger {
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
  .return-to-top.right-side-cookies,
  .privacy-box-trigger.right-side-cookies {
    right: 10px;
  }
  .return-to-top:after {
    font-size: 12px;
  }
  .return-to-top:before {
    inset: -2px;
  }
  body:has(.left-side-cookies) .privacy-box-trigger {
    left: 10px;
  }
  body:has(.left-side-cookies) .privacy-box-trigger:before {
    left: 15px;
    padding: 0 25px 0 34px;
  }
  body:has(.left-side-cookies) .return-to-top {
    right: 10px;
  }
  body:has(.right-side-cookies) .privacy-box-trigger {
    right: 10px;
  }
  body:has(.right-side-cookies) .privacy-box-trigger:before {
    right: 15px;
    padding: 0 34px 0 25px;
  }
  body:has(.right-side-cookies) .return-to-top {
    left: 10px;
  }
  .privacy-box {
    bottom: auto;
    right: auto;
    left: 15px !important;
    right: 15px !important;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100vw;
    max-height: calc(100% - 40px);
  }
}
@media (max-width: 1460px) and (min-width: 1280px) {
  main.aside {
    grid-template-columns: 260px minmax(1px, 1fr);
    gap: var(--rowGap) 30px;
  }
  .detail-main-wrapper {
    grid-template-columns: 555px minmax(0, 1fr) minmax(0, 1fr);
    --fullWidth: 440px;
    --fullHeight: 440px;
    --customWidth: 95px;
    --customHeight: 95px;
  }
  .product-hover > strong {
    max-height: 44px;
    overflow: hidden;
  }
  #detail-right {
    padding: 20px;
  }
}
@media (max-width: 1279px) {
  .detail-omnibus {
    align-self: flex-start;
    margin-top: 0;
    font-size: 11px;
    text-align: left;
  }
  main.aside {
    grid-template-columns: minmax(0, 1fr);
    grid-gap: var(--rowGap) 0;
  }
  * {
    -webkit-tap-highlight-color: rgba(189, 195, 199, 0.75);
    -webkit-text-size-adjust: none;
  }
  input[type=text],
  input[type=password],
  input[type=email],
  textarea {
    font-size: 16px;
    padding: 0 14px;
  }
  select,
  .field-wrapper:not(.checking-reg) select {
    padding: 14px 34px 0 14px;
  }
  textarea {
    padding: 10px 14px;
  }
  #facebook-box,
  .detail-loupe {
    display: none;
  }
  #content {
    width: 100%;
    clear: both;
  }
  .sidebar {
    width: auto;
    display: contents;
  }
  .sidebar .sidebar-tabs {
    display: none;
  }
  #search-engine {
    display: none;
  }
  .newsletter-bg {
    background-image: none !important;
  }
  .newsletter-input {
    width: 100%;
  }
  .footer-content {
    flex-direction: column;
    padding: 20px 0;
  }
  .footer-item {
    width: 100% !important;
  }
  .footer-bottom-bar {
    flex-direction: column;
    gap: 16px;
  }
  body:has(.gs-filtr-container) .filter-trigger {
    display: flex;
  }
  .navigation-wrapper {
    gap: 10px;
  }
  .inner-wrapper [data-dropdown-type="pageSize"] {
    margin-left: auto;
  }
  .dropdown-picker[data-dropdown-type="sort"] {
    display: none;
  }
  .listing-button {
    height: 40px;
    font-size: 13px;
    min-width: 200px;
  }
  .listing-button.filter-trigger {
    min-width: 0;
    gap: 5px;
    align-items: center;
    font-weight: bold;
    color: #1E1E1E;
    border-color: #1E1E1E;
    justify-content: center;
  }
  .listing-button.filter-trigger:before {
    content: 'i sortowanie';
    position: relative;
    top: initial;
    left: initial;
    padding: 0;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    order: 1;
  }
  .listing-button.filter-trigger:after {
    top: calc(50% - 1px);
    right: 16px;
  }
  #filter {
    display: none;
  }
  hr {
    margin: 20px 0;
  }
  .overlay-visible {
    overflow: hidden;
    height: 100dvh;
    width: 100dvw;
    position: fixed;
    top: 0;
  }
  main:not(.aside) #tree {
    display: block;
  }
  .tree-canvas {
    position: fixed;
    background: #000000 none repeat scroll 0% 0%;
    will-change: opacity;
    transition: opacity 0.3s ease 0s;
    inset: 0px;
    width: 100dvw;
    height: 100dvh;
    z-index: 1001;
    pointer-events: none;
    opacity: 0;
  }
  #tree {
    position: fixed;
    top: 0;
    left: 0;
    transform: translate3d(-100%, 0, 0);
    overflow: hidden;
  }
  .user-custom-content p {
    margin-bottom: 10px;
  }
  .cart-header {
    padding-block: 20px;
  }
  .desc-navigation-wrapper {
    margin-bottom: 10px;
  }
  .desc-navigation-wrapper.sticking {
    width: 100dvw;
    transform: translateX(-10px);
  }
  .desc-navigation-wrapper:before,
  .desc-navigation-wrapper:after {
    width: 16px;
    height: calc(100% - 4px);
  }
  .desc-navigation button {
    padding: 6px 16px;
    font-size: 13px;
  }
  .desc-navigation button:after {
    height: 14px;
  }
  .detail-main-wrapper {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }
  #detail-images {
    align-items: center;
    flex-direction: column;
    gap: 20px;
    height: auto;
    left: auto;
    margin: 0 auto;
    position: relative;
    top: auto;
    width: 100%;
  }
  .product-images-mini {
    order: initial;
    height: auto;
    width: 100%;
  }
  .product-images-mini .swiper-wrapper {
    transform: none !important;
    width: auto;
    justify-content: center;
  }
  .product-images-mini .size-custom {
    width: 10px;
    height: 10px;
    background-color: #d6d6d6;
    border-radius: 50%;
    margin: 0 5px;
    padding: 0 !important;
  }
  .product-images-mini img {
    display: none !important;
  }
  .headline {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .top-bar {
    padding: 3px 0 4px;
    font-size: inherit;
  }
  .shop-header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    transition: transform var(--animSpeed) ease-in-out;
  }
  .shop-header.stuck {
    transform: translateY(var(--headerScrollTransform));
  }
  .shop-header-container {
    height: 53px;
  }
  #tree-top,
  .mainmenu {
    display: none;
  }
  .header-tools-wrapper > * {
    padding-top: 25px;
  }
  .header-tools-wrapper > * span,
  .header-tools-wrapper > * b {
    display: none;
  }
  .header-tools-wrapper > *:before {
    transform: translateX(-50%);
    font-size: 22px;
    top: 0;
    left: 50%;
  }
  .header-tools-wrapper > * em {
    display: block;
    color: #1E1E1E;
    font-style: normal;
    text-transform: uppercase;
    font-size: 9px;
    font-weight: 600;
    line-height: 14px;
  }
  #cart-box i {
    right: 4px;
  }
  #wishlist-box:before {
    font-size: 20px;
    top: 1px;
  }
  #wishlist-box:after {
    right: 6px;
    content: '';
  }
  #wishlist-box[data-wishlist-count="0"]:after {
    display: none;
  }
  #wishlist-box:after,
  #cart-box i {
    position: absolute;
    top: 3px;
    border-radius: 50%;
    font-size: 0;
    background-color: #F58220;
    width: 8px;
    height: 8px;
  }
  .hamburger-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 25px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    align-items: center;
  }
  .hamburger-icon span {
    display: block;
    width: 95%;
    height: 2px;
    border-radius: 1px;
    background-color: #1E1E1E;
  }
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    .header-tools-wrapper > * {
      margin-left: 10px;
    }
    .hamburger-icon {
      display: grid;
      grid-auto-rows: max-content;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 6px);
      gap: 0;
      justify-items: center;
      align-items: center;
    }
    .tree-canvas {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .news-banner a.main-page-product-all {
    top: 0;
  }
  .main-banner-wrapper {
    margin-top: 10px;
  }
  .slider-with-products .swiper-button-prev,
  .slider-with-products .swiper-button-next {
    margin-top: -2px;
  }
  .producers-banner {
    width: 100%;
  }
  #detail-tabs nav span {
    border-width: 1px;
    font-size: 15px;
  }
  .logo {
    height: 32px;
  }
  .logo span:before {
    margin: 0 20px;
  }
  .newsletter-header b {
    font-size: 22px;
  }
  a.main-page-product-all {
    margin-top: 3px;
  }
  .mainmenu a {
    height: 34px;
    font-size: inherit;
  }
  .icon-banner-wrapper {
    gap: 10px;
  }
  .slider-with-products .headline {
    margin-bottom: 10px;
  }
  .product-badges {
    font-size: 12px;
  }
  .slider-with-products.slider2 {
    padding: 20px;
    align-content: start;
  }
  .product-single-promoted > div {
    gap: 20px;
  }
  .return-to-top {
    display: none;
  }
  .goshop-projects {
    justify-content: start;
    text-align: initial;
    align-items: start;
  }
  .goshop-projects em {
    font-size: 20px;
  }
  .news-banner {
    padding-top: 0;
  }
  .news-banner:before {
    display: none;
  }
  .news-banner .headline {
    margin-bottom: 10px;
  }
  .news-article {
    width: 100%;
    margin-right: 0;
    padding-left: 310px;
  }
  .news-article figure {
    width: 286px;
  }
  a.footer-contact-link {
    margin-bottom: 20px;
  }
  #newsletter {
    height: auto;
    padding: 20px 10px;
  }
  .footer-item.shortcut div {
    padding-top: 0;
  }
  #breadcrumb {
    padding-block: 10px;
  }
  #breadcrumb li:after {
    margin: 0 5px;
  }
  .category-image {
    margin-right: 10px;
  }
  .detail-headline {
    padding: 0;
    margin-bottom: 0;
    text-align: center;
  }
  .product-images {
    margin: 0 auto;
    order: -2;
  }
  .detail-swiper-button {
    height: 48px;
  }
  #detail-left {
    padding-left: 0;
    min-height: 0;
    margin-bottom: 0;
  }
  #detail-right {
    position: relative;
    box-shadow: none;
    top: auto;
    padding: 10px;
  }
  .detail-options dl:last-child {
    margin-bottom: 16px;
  }
  .detail-accordion-row {
    padding: 12px 0;
  }
  .detail-pricing b {
    font-size: 20px;
  }
  .detail-pricing del {
    font-size: 15px;
    top: -2px;
  }
  .detail-badges span {
    margin-bottom: 8px;
  }
  .detail-badges,
  .detail-pricing {
    justify-content: flex-start;
  }
  span[data-role="sku"]:last-of-type,
  span[data-role="ean"]:last-of-type {
    margin-bottom: 10px;
  }
  .detail-producer-info {
    padding: 10px 0;
    margin-bottom: 10px;
  }
  .detail-short-description {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .detail-buttons {
    gap: 10px;
  }
  .detail-buttons button {
    gap: 8px;
  }
  .social-share {
    margin-top: 10px;
  }
  #main-info {
    margin-top: 0;
  }
}
@media (max-width: 1100px) {
  .comments-list {
    gap: 20px;
  }
  .comment .author:before {
    display: none;
  }
  .comment .added-date {
    margin-left: 0;
    width: 100%;
  }
  .comment .purchase-confirmed {
    width: 100%;
  }
  #detail-traits td {
    padding: 8px 15px;
  }
  .header-shipment {
    display: none;
  }
  .main-banner-wrapper {
    flex-direction: column;
    gap: 10px;
  }
  .main-banner {
    width: 950px;
    max-width: 100%;
    margin: 0 auto;
  }
  .main-banner .text-wrapper b {
    font-size: 20px;
  }
  .main-banner .text-wrapper span {
    font-size: 14px;
  }
  .main-page-promo-row {
    gap: 10px;
  }
  .main-page-promo-row > * {
    width: 50%;
    flex: 1;
  }
  .slider-with-products.slider2 {
    padding: 10px;
    gap: 5px;
  }
  .slider-with-products.slider2 a.main-page-product-all {
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .product-single-promoted > div {
    gap: 10px;
  }
  .product-single-promoted figure {
    width: 40%;
    height: 100%;
  }
  .product-single-promoted .product-single-promoted-info {
    gap: 5px;
  }
  .product-single-promoted .product-name {
    font-size: inherit;
  }
  .goshop-projects {
    padding: 10px;
  }
  .goshop-projects em {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .goshop-projects img {
    align-self: center;
  }
  .main-greeting {
    width: 100%;
    height: auto;
    background-color: white;
    border: 4px solid #3D5B77;
    padding: 12px;
  }
  .main-greeting-headline {
    color: #1E1E1E;
  }
  .main-greeting-headline em {
    font-size: 22px;
  }
  .main-greeting-text {
    color: #7B7B7B;
  }
  a.main-greeting-link {
    background-color: #3D5B77;
    color: white;
  }
  a.main-greeting-link {
    margin-top: 10px;
  }
  .product {
    padding: 10px;
  }
  .product-badges {
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
  }
  .product-price b {
    font-size: 14px;
  }
  .product-price del {
    font-size: 13px;
  }
  .footer-item.submenu ul {
    columns: 1;
  }
}
@media (max-width: 1100px) and (max-width: 675px) {
  .icon-banner-wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (max-width: 600px) {
  .detail-share-links-wrapper span {
    width: 100%;
  }
  .stars-rating.average-rating {
    top: -2px;
  }
  .gs-tabs nav {
    flex-direction: column;
  }
  .gs-tabs nav button {
    width: 100%;
  }
  .user-custom-content iframe {
    max-width: 100%;
  }
  .privacy-box {
    padding: 10px;
    gap: 10px;
  }
  .privacy-box__header {
    gap: 10px;
  }
  .privacy-box__header img {
    height: 20px;
  }
  .privacy-box__header strong {
    font-size: 14px;
  }
  .privacy-box__actions {
    flex-wrap: wrap;
  }
  .privacy-box__actions button {
    min-height: 35px;
  }
  .privacy-box__actions button.privacy-accept-all {
    width: 100%;
    order: -1;
    flex: 0 0 auto;
    min-height: 45px;
  }
  .pagination {
    gap: 4px;
  }
  .pagination a {
    width: 30px;
    height: 30px;
  }
  #breadcrumb {
    display: flex;
    margin-left: -10px;
    width: calc(100% + 20px);
    justify-content: flex-start;
    position: relative;
  }
  #breadcrumb .breadcrumb-end {
    display: block;
    position: absolute;
    top: 0;
    height: calc(100% - 16px);
    width: 20px;
    pointer-events: none;
  }
  #breadcrumb .breadcrumb-end:nth-of-type(1) {
    left: -7px;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff, #ffffff);
  }
  #breadcrumb .breadcrumb-end:nth-of-type(2) {
    right: -7px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff, #ffffff);
  }
  #breadcrumb ul {
    flex-wrap: nowrap;
    overflow-y: auto;
    justify-content: flex-start;
    flex-direction: row-reverse;
    padding-bottom: 5px;
  }
  @supports (selector(::-webkit-scrollbar)) {
    #breadcrumb ul::-webkit-scrollbar-track {
      border-radius: 8px;
      background: #f1f1f1;
    }
    #breadcrumb ul::-webkit-scrollbar {
      height: 5px;
      width: 5px;
      background-color: transparent;
    }
    #breadcrumb ul::-webkit-scrollbar-thumb {
      border-radius: 8px;
      -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
      background-color: #e3e3e3;
    }
    #breadcrumb ul::-webkit-scrollbar-thumb:hover {
      background: #c9c9c9;
    }
    #breadcrumb ul::-webkit-scrollbar-thumb:active {
      background: #b0b0b0;
    }
  }
  @supports (selector(::-webkit-scrollbar)) {
    #breadcrumb ul::-webkit-scrollbar-track {
      margin-inline: 5px;
    }
  }
  #breadcrumb li {
    white-space: nowrap;
  }
  #breadcrumb li:last-of-type {
    padding-right: 10px;
  }
  #breadcrumb li:first-of-type {
    padding-left: 10px;
  }
  #breadcrumb li:nth-of-type(1) {
    order: 10;
  }
  #breadcrumb li:nth-of-type(2) {
    order: 9;
  }
  #breadcrumb li:nth-of-type(3) {
    order: 8;
  }
  #breadcrumb li:nth-of-type(4) {
    order: 7;
  }
  #breadcrumb li:nth-of-type(5) {
    order: 6;
  }
  #gsGalleryContainer > a {
    width: 100%;
  }
  .availability-modal-wrapper .wrapper {
    justify-content: center;
    gap: 10px;
  }
  .availability-modal-wrapper .wrapper > p {
    text-align: center;
  }
  .availability-modal-wrapper .wrapper > .availability-product {
    justify-content: center;
  }
  .availability-modal-wrapper .availability-product {
    gap: 10px;
    padding: 10px 0;
  }
  .availability-modal-wrapper .input-wrapper {
    flex-wrap: wrap;
  }
  .availability-modal-wrapper .input-wrapper > * {
    width: 100%;
  }
  .product-images {
    width: 100%;
  }
  .product-images .size-full {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }
  .detail-amount {
    width: 100%;
    margin: 0 0 10px;
  }
  .pills-group:before,
  .remove-all-filters {
    font-size: 11px;
  }
  .single-pill {
    font-size: 11px;
    padding: 2px 5px;
  }
  #detail-images {
    --swiper-navigation-sides-offset: 0;
  }
  .detail-amount button:before {
    font-size: 22px !important;
  }
  .dropdown-picker[data-dropdown-type="pageSize"] {
    flex-grow: 1;
  }
  .dropdown-picker[data-dropdown-type="pageSize"] .dropdown-button {
    width: 100%;
  }
  .comment .comment-response {
    margin-left: 15px;
    padding: 10px;
  }
  .header-tools-wrapper {
    gap: 12px;
  }
  .navigation-wrapper {
    padding-top: 0;
    border-top: 0;
  }
  a.footer-contact-link {
    display: flex;
    width: fit-content;
    margin-left: 0;
  }
  .listing-wrapper {
    gap: 10px;
  }
  .listing-wrapper > hr {
    display: none;
  }
  .header-social-links {
    display: none;
  }
  .header-contact {
    margin-right: 0 !important;
  }
  .category-image {
    display: none;
  }
  .mainmenu {
    padding: 0 10px;
  }
  .mainmenu li:nth-child(1) {
    display: none;
  }
  .logo span {
    display: none;
  }
  .main-banner {
    height: 270px;
  }
  .main-banner .text-wrapper {
    padding: 10px;
    max-width: calc(100% - 20px);
    left: 10px;
    bottom: 23px;
  }
  .main-banner .text-wrapper b {
    font-size: 16px;
  }
  .main-banner .text-wrapper span {
    font-size: 13px;
  }
  .swiper-pagination.banner-pagination {
    bottom: -7px;
    left: 10px;
  }
  .main-greeting {
    padding: 10px;
  }
  .slider-with-products .headline {
    margin-bottom: 10px;
  }
  .slider-with-products .swiper-button-prev,
  .slider-with-products .swiper-button-next {
    width: 25px;
  }
  .slider-with-products .swiper-button-prev {
    margin-left: 10px;
  }
  a.main-page-product-all {
    margin: 3px 0 0 0;
  }
  .news-banner-wrapper {
    gap: 10px;
  }
  .news-article {
    width: calc((100% - 10px) / 2);
    padding: 0;
  }
  .news-article figure {
    position: static;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  .news-article b {
    font-size: 13px;
  }
  .news-article p {
    margin-top: 8px;
  }
  .news-banner a.main-page-product-all {
    top: 3px;
  }
  #detail-traits {
    display: block;
  }
  #detail-traits tbody,
  #detail-traits tr,
  #detail-traits td {
    display: block;
    width: 100%;
  }
  #detail-traits tr {
    padding: 8px 0;
  }
  #detail-traits td {
    padding: 0 0;
  }
  #detail-traits td:nth-child(1) {
    text-align: left;
    border-bottom: 0;
  }
  #detail-traits td:nth-child(2) {
    padding-bottom: 8px;
    padding-top: 3px;
  }
  .user-custom-content #askbox,
  .add-comment-form {
    padding-right: 0;
  }
  .user-custom-content #askbox:after,
  .add-comment-form:after {
    display: none;
  }
  .producers-banner {
    padding: 0 30px;
  }
  .main-page-promo-row {
    flex-direction: column;
    gap: 20px;
  }
  .slider-with-products.slider2,
  .goshop-projects {
    width: 100%;
  }
  .slider2 .swiper-slide:not(.swiper-slide-active) {
    opacity: 0;
  }
  .slider2 .product {
    width: 100% !important;
    margin: 0 !important;
  }
  .product-single-promoted figure {
    width: 40%;
    height: 100%;
  }
  .product-single-promoted-info {
    gap: 0;
  }
  .category-header-wrapper {
    display: contents;
  }
  .category-description {
    order: 2;
  }
}
@media (max-width: 600px) and (max-width: 350px) {
  .logo {
    flex-shrink: 1;
    max-width: 40%;
    aspect-ratio: initial;
  }
}
@media (max-width: 600px) and (max-width: 380px) {
  .navigation-wrapper {
    flex-direction: column;
  }
  .dropdown-picker {
    width: 100%;
    z-index: 11;
  }
  .listing-button {
    min-width: 0 !important;
    width: 100% !important;
  }
  .filter-trigger {
    order: 1;
  }
}
@media (max-width: 600px) and (max-width: 350px) {
  .icon-banner-wrapper {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
